<template>
  <div id="portal-settings">
    <!-- settings -->

    <!-- action bar -->

    <ActionBar v-model="tab" :tabs="tabs" @save="saveSettings" />

    <!-- ... -->

    <BaseScrollbar height="calc(100vh - 125px)">
      <div class="row q-pb-xl">
        <ValidationObserver ref="portal">
          <!-- general settings -->

          <GeneralSettings
            ref="GENERAL_SETTINGS"
            :portal="portal"
            :mode="mode"
            :name.sync="name"
            :description.sync="description"
            :workflow.sync="workflow"
            :superuser.sync="superuser"
            :workflow-list="workflowList"
            :user-list="userList"
            :is-active="tab === 'GENERAL_SETTINGS'"
            :workflow-category-settings.sync="workflowCategorySettings"
            @click="tab = 'GENERAL_SETTINGS'"
          />

          <!-- ... -->

          <BaseSeparator has-left-inset />

          <!-- authentication settings -->

          <AuthenticationSettings
            ref="AUTHENTICATION_SETTINGS"
            feature="Portal Authentication"
            :authentication.sync="authentication"
            :form-list="formList"
            :is-active="tab === 'AUTHENTICATION_SETTINGS'"
            @click="tab = 'AUTHENTICATION_SETTINGS'"
            @signIn="signIn"
          />

          <!-- ... -->

          <BaseSeparator has-left-inset />

          <!-- notification settings -->

          <Notification
            ref="NOTIFICATIONS_SETTINGS"
            feature="Portal Authentication"
            :authentication.sync="authentication"
            :form-list="formList"
            :is-active="tab === 'NOTIFICATIONS_SETTINGS'"
            @click="tab = 'NOTIFICATIONS_SETTINGS'"
          />

          <!-- ... -->

          <HowItWorksSettings
            ref="HOW_IT_WORKS"
            :is-active="tab === 'HOW_IT_WORKS'"
            :section-settings.sync="sectionSettings"
            feature="Settings"
          />

          <!-- <WorkInProgress
            v-if="tab === 'NOTIFICATIONS_SETTINGS'"
            feature="Portal Notifications"
          /> -->
          <WorkInProgress
            v-if="tab === 'WELCOME_PAGE_SETTINGS'"
            feature="Portal Welcome Page"
          />
          <WorkInProgress
            v-if="tab === 'THANK_YOU_PAGE_SETTINGS'"
            feature="Thank you Page"
          />
          <WorkInProgress
            v-if="tab === 'THANK_YOU_PAGE_SETTINGS'"
            feature="Thank you Page"
          />
          <WorkInProgress
            v-if="tab === 'HEADER_AND_FOOTER'"
            feature="Header & Footer"
          />
        </ValidationObserver>

        <!-- ... -->
      </div>
    </BaseScrollbar>

    <!-- ... -->
  </div>
</template>

<script>
import ActionBar from "../components/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import GeneralSettings from "./components/general-settings/GeneralSettings.vue";
import AuthenticationSettings from "./components/general-settings/AuthenticationSettings.vue";
import { cloneDeep } from "lodash-es";
import WorkInProgress from "@/components/common/state/WorkInProgress.vue";
import Notification from "./components/general-settings/NotificationSettings.vue";
import HowItWorksSettings from "./components/general-settings/HowItWorksSettings.vue";
import { formJson } from "./components/general-settings/portalForm.js";
import { form } from "@/api/factory.js";

export default {
  name: "PortalSettings",

  components: {
    ActionBar,
    ValidationObserver,
    GeneralSettings,
    AuthenticationSettings,
    WorkInProgress,
    Notification,
    HowItWorksSettings,
  },

  props: {
    portal: {
      type: Object,
      required: true,
    },

    workflowList: {
      type: Array,
      default: () => [],
    },

    userList: {
      type: Array,
      default: () => [],
    },

    formList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "GENERAL_SETTINGS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "authentication",
          value: "AUTHENTICATION_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "notifications",
          value: "NOTIFICATIONS_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "how It Works",
          value: "HOW_IT_WORKS",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "welcome page",
        //   value: "WELCOME_PAGE_SETTINGS",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "thank you page",
        //   value: "THANK_YOU_PAGE_SETTINGS",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "header & footer",
        //   value: "HEADER_AND_FOOTER",
        // },
      ],

      // general settings

      name: "",
      description: "",
      workflow: 0,
      superuser: [],

      // specific settings
      authentication: {
        loginType: "",
        formId: 0,
        usernameField: [],
        passwordField: "",
        firstnameField: "",
        socialLogin: [],
        passwordTypes: "OTP",
        notification: false,
      },
      workflowCategorySettings: [],
      sectionSettings: [],
      signInValue: false,
    };
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    portal: {
      immediate: true,
      deep: true,
      handler() {
        const portalSettings = cloneDeep(this.portal.settings);
        this.name = portalSettings.general.name;
        this.description = portalSettings.general.description;
        this.workflow = portalSettings.general.workflow;
        this.superuser = portalSettings.general.superuser;
        this.authentication = portalSettings.specific.authentication;
        if (typeof this.authentication.usernameField === "string") {
          this.authentication.usernameField = [
            this.authentication.usernameField,
          ];
        }
        if (portalSettings.contentJson) {
          let contentJson = JSON.parse(portalSettings.contentJson);
          contentJson.workflows.forEach((workflow) => {
            this.workflowCategorySettings.push({
              id: this.$nano.id(),
              workflowId: workflow.id,
              formFields: workflow.processInfo,
              categoryField: workflow.categoryFieldId,
              categorySettings: workflow.category,
              categoryFieldMasterSync: workflow.categoryFieldMasterSync,
            });
          });

          contentJson.howItWorks.forEach((setting) => {
            this.sectionSettings.push({
              id: this.$nano.id(),
              title: setting.title,
              description: setting.description,
              imageURL: setting.imageURL,
              linkTo: setting.linkTo,
              file: [],
            });
          });
        } else if (this.workflow) {
          this.workflowCategorySettings.push({
            id: this.$nano.id(),
            workflowId: this.workflow,
            formFields: [],
            categoryField: "",
            categorySettings: [],
            categoryFieldMasterSync: [],
          });
        }
      },
    },
  },

  methods: {
    signIn(value) {
      this.signInValue = value;
    },

    async saveSettings() {
      const isValid = await this.$refs.portal.validate();

      if (!isValid) {
        return;
      }
      if (!this.workflowCategorySettings.length) {
        this.$alert.info("Required anyone workflow to link");
        return;
      }
      if (this.signInValue && !this.authentication.formId) {
        const data = formJson();
        data.settings.publish.publishOption = "PUBLISHED";
        data.settings.general.name = this.name + " - Authentication";
        data.settings.general.type = "MASTER";
        console.log(data);
        this.isLoading = true;
        this.$store.commit("showLoadingBar");

        const { payload, error } = await form.createForm(data);

        if (error) {
          if (error !== "error creating form") {
            this.$alert.warning(error);
          } else {
            this.$alert.error(error);
          }
          this.$store.commit("hideLoadingBar");
          this.isLoading = false;
          return;
        }
        console.log(this.authentication, "auth1");
        if (this.signInValue) {
          this.authentication = {};
          this.authentication.loginType = "MASTER_LOGIN";
          this.authentication.socialLogin = [];
          this.authentication.formId = Number(payload);
          this.formFields = [];
          this.authentication.usernameField = ["FOOMsLyaDKj3lYG4eeZqb"];
          this.authentication.firstnameField = "dP-e4HjIltIzXefTcRQ7O";
          this.authentication.passwordField = "jPrBG4saATqt9imz-YG3q";
          this.authentication.passwordTypes = "OTP";
          this.authentication.signInType = this.signInValue;
        }
      }
      const portalSettings = cloneDeep(this.portal.settings);

      portalSettings.general.name = this.name;
      portalSettings.general.description = this.description;
      portalSettings.general.workflow = this.workflow;
      portalSettings.general.superuser = this.superuser;
      portalSettings.specific.authentication = this.authentication;
      let category = [];
      this.workflowCategorySettings.forEach((setting, index) => {
        if (index === 0) {
          portalSettings.general.workflow = setting.workflowId;
        }
        category.push({
          id: setting.workflowId,
          categoryFieldId: setting.categoryField,
          processInfo: setting.formFields,
          category: setting.categorySettings,
          categoryFieldMasterSync: setting.categoryFieldMasterSync,
        });
      });
      let howItWorks = [];
      this.sectionSettings.forEach((section) => {
        howItWorks.push({
          title: section.title,
          description: section.description,
          imageURL: section.imageURL,
          linkTo: section.linkTo,
        });
      });
      let settings = {
        workflows: category,
        howItWorks: howItWorks,
      };
      portalSettings.contentJson = JSON.stringify(settings);
      if (!this.workflowCategorySettings.length) {
        this.$alert.info("Required anyone workflow to link");
        return;
      }
      this.$emit("update", portalSettings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
