<template>
  <FormSection
    :icon="isActive ? 'mdi-account-lock' : 'mdi-account-lock-outline'"
    :is-active="isActive"
    class="authentication-settings"
    v-on="$listeners"
  >
    <template #title>Authentication</template>
    <template #description>
      Verifies the identity of a user attempting to gain access the portal
    </template>

    <!-- login authentication types -->

    <SingleChoiceField
      :value="authentication.signInType"
      label="New Registration"
      :options="signInTypes"
      :options-per-line="2"
      is-mandatory
      class="q-pb-md"
      @input="updateSignIn"
    />
    <div v-if="!authentication.signInType">
      <SingleChoiceField
        :value="authentication.loginType"
        label="login types"
        :options="loginTypes"
        :options-per-line="1"
        is-mandatory
        class="q-pb-md"
        @input="updateLoginType"
      />

      <SingleChoiceField
        :value="authentication.loginType"
        :options="loginApplications"
        :options-per-line="1"
        is-mandatory
        :show-options-wrapper="false"
        class="q-pb-md"
        @input="updateLoginType"
      />

      <template v-if="authentication.loginType === 'APP_LOGIN'">
        <!-- login authentication additional types-->
        <div class="q-ml-lg">
          <MultipleChoiceField
            :value="authentication.socialLogin"
            :options="additionalLoginTypeOptions"
            :options-per-line="1"
            label="Social Login"
            class="q-ml-sm q-pb-md"
            @input="updateAdditionalLoginTypes"
          />
        </div>
      </template>

      <SingleChoiceField
        :value="authentication.loginType"
        :options="loginMaster"
        :options-per-line="1"
        is-mandatory
        :show-options-wrapper="false"
        class="q-pb-md"
        @input="updateLoginType"
      />

      <template v-if="authentication.loginType === 'MASTER_LOGIN'">
        <div class="q-ml-lg">
          <!-- Forms -->
          <SelectField
            :value="authentication.formId"
            is-mandatory
            label="Master Form"
            :options="formList"
            class="q-ml-sm"
            @input="updateFormId"
          />
          <!-- ... -->

          <!-- username field -->
          <MultiSelectField
            :value="authentication.usernameField"
            is-mandatory
            label="Username Field"
            :options="formFields"
            class="q-pa-sm"
            @input="updateUsernameField"
          />
          <!-- ... -->
          <!-- firstname field -->
          <SelectField
            :value="authentication.firstnameField"
            is-mandatory
            label="First Name Field"
            :options="formFields"
            class="q-pa-sm q-pb-md"
            @input="updateFirstnameField"
          />
          <!-- ... -->

          <SingleChoiceField
            :value="authentication.passwordTypes"
            :options="PasswordTypeOtp"
            :options-per-line="1"
            is-mandatory
            :show-options-wrapper="false"
            class="q-pb-md"
            @input="updatePasswordTypes"
          />

          <SingleChoiceField
            :value="authentication.passwordTypes"
            :options="PasswordType"
            :options-per-line="1"
            is-mandatory
            :show-options-wrapper="false"
            class="q-pb-md"
            @input="updatePasswordTypes"
          />

          <template v-if="authentication.passwordTypes === 'PASSWORD'">
            <div class="q-ml-lg">
              <SelectField
                :value="authentication.passwordField"
                is-mandatory
                label="Password Field"
                :options="formFields"
                class="q-ml-sm q-pb-md"
                @input="updatePasswordField"
              />
            </div>
          </template>

          <SingleChoiceField
            :value="authentication.passwordTypes"
            :options="socialType"
            :options-per-line="1"
            is-mandatory
            :show-options-wrapper="false"
            class="q-pb-md"
            @input="updatePasswordTypes"
          />

          <template v-if="authentication.passwordTypes === 'SOCIAL_LOGIN'">
            <!-- login authentication additional types-->
            <div class="q-ml-lg">
              <MultipleChoiceField
                :value="authentication.socialLogin"
                :options="additionalLoginTypeOptions"
                :options-per-line="1"
                :show-options-wrapper="false"
                class="q-ml-sm"
                @input="updateAdditionalLoginTypes"
              />
            </div>
            <!-- ... -->
          </template>
        </div>
      </template>
    </div>
    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import { form } from "@/api/factory.js";

export default {
  name: "AuthenticationSettings",

  components: {
    FormSection,
    SingleChoiceField,
    MultipleChoiceField,
    SelectField,
    MultiSelectField,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    authentication: {
      type: Object,
      default: () => {},
    },

    formList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loginTypes: [
        {
          id: this.$nano.id(),
          label: "Email with OTP",
          description: "Sends OTP to the Email",
          value: "EMAIL_LOGIN",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Mobile with OTP",
        //   description: "Sends OTP to the Mobile",
        //   value: "MOBILE_LOGIN",
        // },
      ],
      loginApplications: [
        {
          id: this.$nano.id(),
          label: "Application Login",
          description: "Validate accounts to application users",
          value: "APP_LOGIN",
        },
      ],
      loginMaster: [
        {
          id: this.$nano.id(),
          label: "Master Login",
          description: "Validate accounts to master",
          value: "MASTER_LOGIN",
        },
      ],
      PasswordTypeOtp: [
        {
          id: this.$nano.id(),
          label: " Login With OTP",
          description: "Use A One Time Password For Initial Login",
          value: "OTP",
        },
      ],
      PasswordType: [
        {
          id: this.$nano.id(),
          label: "Login Password Field",
          description:
            "Use The Login Password field From The Master For Authentication",
          value: "PASSWORD",
        },
      ],
      socialType: [
        {
          id: this.$nano.id(),
          label: "Social Login",
          description:
            "Enable Users To Log In Using Their Google (or) Microsoft Accounts",
          value: "SOCIAL_LOGIN",
        },
      ],
      additionalLoginTypeOptions: [
        {
          id: this.$nano.id(),
          label: "Google",
          value: "GOOGLE_LOGIN",
        },
        {
          id: this.$nano.id(),
          label: "Microsoft",
          value: "MICROSOFT_LOGIN",
        },
      ],
      formFields: [],
      hideLogin: false,
      signInTypes: [
        {
          id: this.$nano.id(),
          label: "Yes",
          value: true,
        },
        {
          id: this.$nano.id(),
          label: "No",
          value: false,
        },
      ],
    };
  },

  watch: {
    authentication: {
      immediate: true,
      handler() {
        if (this.authentication.formId) {
          this.getFormFields(this.authentication.formId);
        }
      },
    },
  },

  methods: {
    updateLoginType(value) {
      this.authentication.loginType = value;
      this.authentication.socialLogin = [];
      this.authentication.formId = 0;
      this.formFields = [];
      this.authentication.usernameField = [];
      this.authentication.firstnameField = "";
      this.authentication.passwordField = "";
      this.authentication.passwordTypes = "OTP";
      this.$emit("update:authentication", this.authentication);
    },

    updateFormId(value) {
      this.authentication.formId = value;
      this.$emit("update:authentication", this.authentication);
      this.getFormFields(value);
    },

    updateUsernameField(value) {
      this.authentication.usernameField = value;
      this.$emit("update:authentication", this.authentication);
    },

    updatePasswordField(value) {
      this.authentication.passwordField = value;
      this.$emit("update:authentication", this.authentication);
    },

    updatePasswordTypes(value) {
      this.authentication.passwordTypes = value;
      if (this.authentication.passwordTypes === "OTP") {
        this.authentication.passwordField = "";
        this.authentication.socialLogin = [];
      }
      if (this.authentication.passwordTypes === "SOCIAL_LOGIN") {
        this.authentication.passwordField = "";
      }
      if (this.authentication.passwordTypes === "PASSWORD") {
        this.authentication.socialLogin = [];
      }
      this.$emit("update:authentication", this.authentication);
    },

    updateFirstnameField(value) {
      this.authentication.firstnameField = value;
      this.$emit("update:authentication", this.authentication);
    },

    updateAdditionalLoginTypes(value) {
      this.authentication.socialLogin = value;
      this.$emit("update:authentication", this.authentication);
    },

    updateSignIn(value) {
      this.authentication.signInType = value;
      this.$emit("signIn", value);
      this.$emit("update:authentication", this.authentication);
    },

    async getFormFields(formId) {
      this.formFields = [];
      if (formId) {
        const { error, payload } = await form.getForm(formId);

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload) {
          let form = JSON.parse(payload.formJson);
          let masterfields = [];
          const panels = [...form.panels, ...form.secondaryPanels];

          if (!panels.length) {
            return;
          }
          for (const panel of panels) {
            masterfields.push(...panel.fields);
          }

          masterfields.forEach((field) => {
            if (field.type !== "DIVIDER") {
              this.formFields.push({
                id: field.id,
                label: field.label,
                value: field.id,
              });
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
