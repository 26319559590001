export function formJson() {
  return {
    uid: "z_vSCdIKAqc_fa3fQX8Gq",
    panels: [
      {
        id: "Z7JKQOI4FzShLaG7Y2SRw",
        settings: {
          title: "",
          description: "",
        },
        fields: [
          {
            id: "dP-e4HjIltIzXefTcRQ7O",
            label: "FirstName",
            type: "SHORT_TEXT",
            settings: {
              general: {
                hideLabel: false,
                size: "col-6",
                visibility: "NORMAL",
                placeholder: "",
                tooltip: "",
                dividerType: "SOLID",
                url: "",
              },
              specific: {
                optionsType: "CUSTOM",
                masterTable: "",
                masterTableColumn: "",
                customOptions: "Option1, Option2",
                separateOptionsUsing: "COMMA",
                allowToAddNewOptions: false,
                optionsPerLine: 0,
                defaultValue: "CUSTOM",
                autoGenerateValue: {
                  prefix: "Form",
                  suffix: "DATE_TIME",
                },
                customDefaultValue: "",
                showColumnTotal: false,
                allowMultipleFiles: false,
                ratingIcon: "STAR",
                ratingIconCount: 5,
                allowHalfRating: false,
                allowMultipleSignatures: false,
                tableColumns: [],
                tableRowsType: "ON_DEMAND",
                tableFixedRowCount: 0,
                qrValue: false,
                tableFixedRowLabels: [],
                matrixColumns: [
                  {
                    id: "vgxLh_gMsQJtOSjjo3TkE",
                    label: "column",
                  },
                  {
                    id: "frajazlsyMg06qgVlkqPF",
                    label: "column",
                  },
                  {
                    id: "c1vjPvPBbLKQE78XtKBnt",
                    label: "column",
                  },
                ],
                matrixRows: [
                  {
                    id: "IPhW88BxYEuyUcxEPZrDW",
                    label: "row",
                  },
                  {
                    id: "BodkVHeKUCcqQTaPNv-_x",
                    label: "row",
                  },
                  {
                    id: "9eaLDeIP7aUuiKAvmMt8c",
                    label: "row",
                  },
                ],
                matrixType: "SHORT_TEXT",
                matrixTypeSettings: {},
                textContent: "",
                fibFields: [],
                tabList: [
                  {
                    id: "hVq_phx1JPYsAv4osKNM2",
                    label: "tab1",
                    value: "tab1",
                    panel: 0,
                  },
                  {
                    id: "cOzKlBYwZuWrAcEAlpYUH",
                    label: "tab2",
                    value: "tab2",
                    panel: 0,
                  },
                ],
                popupTriggerType: "BUTTON",
                secondaryPanel: "",
                mappedFieldId: "",
                mappedColumnId: "",
                nestedListMaxLevel: 3,
                nestedList: [
                  {
                    id: "OaknI1gXwxbMx024l2J46",
                    label: "Item1",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                  {
                    id: "Yh0SlspRHPHVzdehH04w-",
                    label: "Item2",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                ],
                nestedListItemsPerLine: [],
                nestedListFieldType: "SHORT_TEXT",
                nestedListTypeSettings: {},
                formula: "",
                loginType: "EZOFIS_LOGIN",
                additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                masterFormTableColumns: [],
                repositoryId: 0,
                repositoryField: "",
                repositoryFieldParent: "",
                addressMatchingFields: [
                  {
                    id: "LfVMjfRJ3PrDstccsMNW7",
                    addressColumn: "CITY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "RBlPr79NkJCW1yOzU_jf1",
                    addressColumn: "COUNTRY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "pDRnn76ED8SkSx78vP548",
                    addressColumn: "STATE",
                    selectFieldColumn: [],
                  },
                  {
                    id: "1e5HEZgl-BOLF59_RyD5k",
                    addressColumn: "POSTALCODE",
                    selectFieldColumn: [],
                  },
                ],
                formSyncField: [],
                defaultValueInSelectField: "NONE",
              },
              validation: {
                fieldRule: "OPTIONAL",
                contentRule: "TEXT",
                minimum: "",
                maximum: "",
                allowedFileTypes: [],
                maxFileSize: 10,
                dateRange: "NONE",
                timeRange: "NONE",
                maxiDays: 0,
                miniDays: 0,
                maxiTime: 0,
                miniTime: 0,
                answerIndicator: "NO",
                documentExpiryField: "",
                enableSettings: [],
                mandatorySettings: [],
                readonlySettings: [],
                timeFormat: "12",
              },
              aiSettings: {
                formControlValidate: {
                  optionsType: "",
                  masterFormId: 0,
                  masterFormColumn: [],
                  formFields: "",
                  existingFormFields: [],
                  conditionFields: [],
                },
                fileDataValidate: [],
                imageFileValidate: {
                  optionsType: "",
                  formFields: [],
                },
              },
            },
            dateFilter: 0,
          },
          {
            id: "JpQFDRwLMCqRSNEPxVOCT",
            label: "LastName",
            type: "SHORT_TEXT",
            settings: {
              general: {
                hideLabel: false,
                size: "col-6",
                visibility: "NORMAL",
                placeholder: "",
                tooltip: "",
                dividerType: "SOLID",
                url: "",
              },
              specific: {
                optionsType: "CUSTOM",
                masterTable: "",
                masterTableColumn: "",
                customOptions: "Option1, Option2",
                separateOptionsUsing: "COMMA",
                allowToAddNewOptions: false,
                optionsPerLine: 0,
                defaultValue: "CUSTOM",
                autoGenerateValue: {
                  prefix: "Form",
                  suffix: "DATE_TIME",
                },
                customDefaultValue: "",
                showColumnTotal: false,
                allowMultipleFiles: false,
                ratingIcon: "STAR",
                ratingIconCount: 5,
                allowHalfRating: false,
                allowMultipleSignatures: false,
                tableColumns: [],
                tableRowsType: "ON_DEMAND",
                tableFixedRowCount: 0,
                qrValue: false,
                tableFixedRowLabels: [],
                matrixColumns: [
                  {
                    id: "7nP4oMY9oIInP_3UOKTst",
                    label: "column",
                  },
                  {
                    id: "rUwAtCYzONHRs6ekexr9X",
                    label: "column",
                  },
                  {
                    id: "_htIObM1sMogYjs3vlghi",
                    label: "column",
                  },
                ],
                matrixRows: [
                  {
                    id: "_J1vbD5EqysJfE9v3uodQ",
                    label: "row",
                  },
                  {
                    id: "fciz4Zw0MNGzyLvjqX9Ae",
                    label: "row",
                  },
                  {
                    id: "kbqObq8lSTyVRb9skkV5U",
                    label: "row",
                  },
                ],
                matrixType: "SHORT_TEXT",
                matrixTypeSettings: {},
                textContent: "",
                fibFields: [],
                tabList: [
                  {
                    id: "kJAt0LYddJ_IYpffZeMuC",
                    label: "tab1",
                    value: "tab1",
                    panel: 0,
                  },
                  {
                    id: "mfCWJSKHdliVgH1X4UOxe",
                    label: "tab2",
                    value: "tab2",
                    panel: 0,
                  },
                ],
                popupTriggerType: "BUTTON",
                secondaryPanel: "",
                mappedFieldId: "",
                mappedColumnId: "",
                nestedListMaxLevel: 3,
                nestedList: [
                  {
                    id: "uLFF3WBHqtbl8mZimUEQQ",
                    label: "Item1",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                  {
                    id: "OPFJqKffh2k_gF38xdjPM",
                    label: "Item2",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                ],
                nestedListItemsPerLine: [],
                nestedListFieldType: "SHORT_TEXT",
                nestedListTypeSettings: {},
                formula: "",
                loginType: "EZOFIS_LOGIN",
                additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                masterFormTableColumns: [],
                repositoryId: 0,
                repositoryField: "",
                repositoryFieldParent: "",
                addressMatchingFields: [
                  {
                    id: "BjhwBL2aSRL8gyWC45dwv",
                    addressColumn: "CITY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "shkwMcuxaT2UXK710k8WQ",
                    addressColumn: "COUNTRY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "kQam_ZlFGzfwHYuMz089i",
                    addressColumn: "STATE",
                    selectFieldColumn: [],
                  },
                  {
                    id: "rQQei2a1EoAbqACTqJ4OG",
                    addressColumn: "POSTALCODE",
                    selectFieldColumn: [],
                  },
                ],
                formSyncField: [],
                defaultValueInSelectField: "NONE",
              },
              validation: {
                fieldRule: "OPTIONAL",
                contentRule: "TEXT",
                minimum: "",
                maximum: "",
                allowedFileTypes: [],
                maxFileSize: 10,
                dateRange: "NONE",
                timeRange: "NONE",
                maxiDays: 0,
                miniDays: 0,
                maxiTime: 0,
                miniTime: 0,
                answerIndicator: "NO",
                documentExpiryField: "",
                enableSettings: [],
                mandatorySettings: [],
                readonlySettings: [],
                timeFormat: "12",
              },
              aiSettings: {
                formControlValidate: {
                  optionsType: "",
                  masterFormId: 0,
                  masterFormColumn: [],
                  formFields: "",
                  existingFormFields: [],
                  conditionFields: [],
                },
                fileDataValidate: [],
                imageFileValidate: {
                  optionsType: "",
                  formFields: [],
                },
              },
            },
            dateFilter: 0,
          },
          {
            id: "FOOMsLyaDKj3lYG4eeZqb",
            label: "Email",
            type: "SHORT_TEXT",
            settings: {
              general: {
                hideLabel: false,
                size: "col-6",
                visibility: "NORMAL",
                placeholder: "",
                tooltip: "",
                dividerType: "SOLID",
                url: "",
              },
              specific: {
                optionsType: "CUSTOM",
                masterTable: "",
                masterTableColumn: "",
                customOptions: "Option1, Option2",
                separateOptionsUsing: "COMMA",
                allowToAddNewOptions: false,
                optionsPerLine: 0,
                defaultValue: "CUSTOM",
                autoGenerateValue: {
                  prefix: "Form",
                  suffix: "DATE_TIME",
                },
                customDefaultValue: "",
                showColumnTotal: false,
                allowMultipleFiles: false,
                ratingIcon: "STAR",
                ratingIconCount: 5,
                allowHalfRating: false,
                allowMultipleSignatures: false,
                tableColumns: [],
                tableRowsType: "ON_DEMAND",
                tableFixedRowCount: 0,
                qrValue: false,
                tableFixedRowLabels: [],
                matrixColumns: [
                  {
                    id: "wrJIG1oJfzaHgrXOXjlwT",
                    label: "column",
                  },
                  {
                    id: "fx6XTc8E1J-a7tqs435wM",
                    label: "column",
                  },
                  {
                    id: "Y10X2grnSGVS7UX2LvqTy",
                    label: "column",
                  },
                ],
                matrixRows: [
                  {
                    id: "Q_qnNdX8OijVMuhu9IEzD",
                    label: "row",
                  },
                  {
                    id: "zoe-ej6k2Xcg8-89nRrrO",
                    label: "row",
                  },
                  {
                    id: "K3hrvdMhH8wdKiGDTmsgN",
                    label: "row",
                  },
                ],
                matrixType: "SHORT_TEXT",
                matrixTypeSettings: {},
                textContent: "",
                fibFields: [],
                tabList: [
                  {
                    id: "sAOlkZaKtNQ18HR8dKKA8",
                    label: "tab1",
                    value: "tab1",
                    panel: 0,
                  },
                  {
                    id: "yVp3LhZe78N_yVBTcaz12",
                    label: "tab2",
                    value: "tab2",
                    panel: 0,
                  },
                ],
                popupTriggerType: "BUTTON",
                secondaryPanel: "",
                mappedFieldId: "",
                mappedColumnId: "",
                nestedListMaxLevel: 3,
                nestedList: [
                  {
                    id: "GDanMYeqQHiESSoHmrzxJ",
                    label: "Item1",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                  {
                    id: "U_7qLRH1bXmzAM5RIOVOe",
                    label: "Item2",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                ],
                nestedListItemsPerLine: [],
                nestedListFieldType: "SHORT_TEXT",
                nestedListTypeSettings: {},
                formula: "",
                loginType: "EZOFIS_LOGIN",
                additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                masterFormTableColumns: [],
                repositoryId: 0,
                repositoryField: "",
                repositoryFieldParent: "",
                addressMatchingFields: [
                  {
                    id: "gEFDFMmCOeTorncJ7Riux",
                    addressColumn: "CITY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "fOfxNRHuH3OXrdQuwp4vj",
                    addressColumn: "COUNTRY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "bC0CUf_D5f8FFwGgA6VEl",
                    addressColumn: "STATE",
                    selectFieldColumn: [],
                  },
                  {
                    id: "xGGJflj_qVTcBrMNB8mmH",
                    addressColumn: "POSTALCODE",
                    selectFieldColumn: [],
                  },
                ],
                formSyncField: [],
                defaultValueInSelectField: "NONE",
              },
              validation: {
                fieldRule: "OPTIONAL",
                contentRule: "TEXT",
                minimum: "",
                maximum: "",
                allowedFileTypes: [],
                maxFileSize: 10,
                dateRange: "NONE",
                timeRange: "NONE",
                maxiDays: 0,
                miniDays: 0,
                maxiTime: 0,
                miniTime: 0,
                answerIndicator: "NO",
                documentExpiryField: "",
                enableSettings: [],
                mandatorySettings: [],
                readonlySettings: [],
                timeFormat: "12",
              },
              aiSettings: {
                formControlValidate: {
                  optionsType: "",
                  masterFormId: 0,
                  masterFormColumn: [],
                  formFields: "",
                  existingFormFields: [],
                  conditionFields: [],
                },
                fileDataValidate: [],
                imageFileValidate: {
                  optionsType: "",
                  formFields: [],
                },
              },
            },
            dateFilter: 0,
          },
          {
            id: "VJt5fIJvTk5EWCndPIOH8",
            label: "PhoneNumber",
            type: "NUMBER",
            settings: {
              general: {
                hideLabel: false,
                size: "col-6",
                visibility: "NORMAL",
                placeholder: "",
                tooltip: "",
                dividerType: "SOLID",
                url: "",
              },
              specific: {
                optionsType: "CUSTOM",
                masterTable: "",
                masterTableColumn: "",
                customOptions: "Option1, Option2",
                separateOptionsUsing: "COMMA",
                allowToAddNewOptions: false,
                optionsPerLine: 0,
                defaultValue: "CUSTOM",
                autoGenerateValue: {
                  prefix: "Form",
                  suffix: "DATE_TIME",
                },
                customDefaultValue: "",
                showColumnTotal: false,
                allowMultipleFiles: false,
                ratingIcon: "STAR",
                ratingIconCount: 5,
                allowHalfRating: false,
                allowMultipleSignatures: false,
                tableColumns: [],
                tableRowsType: "ON_DEMAND",
                tableFixedRowCount: 0,
                qrValue: false,
                tableFixedRowLabels: [],
                matrixColumns: [
                  {
                    id: "alJeuVzHGihkLWcwb7ga_",
                    label: "column",
                  },
                  {
                    id: "iCi1f-fQSBKPn0HWPdtfd",
                    label: "column",
                  },
                  {
                    id: "bim_mnxThIpOKLyhvwbRN",
                    label: "column",
                  },
                ],
                matrixRows: [
                  {
                    id: "XJ7uOV2iv1PtmahetSdYJ",
                    label: "row",
                  },
                  {
                    id: "l0rJ36fhikEw-9dz_dMGG",
                    label: "row",
                  },
                  {
                    id: "mz_LZhEcwPPZVXFjgeMUq",
                    label: "row",
                  },
                ],
                matrixType: "SHORT_TEXT",
                matrixTypeSettings: {},
                textContent: "",
                fibFields: [],
                tabList: [
                  {
                    id: "9pynFHHJbBhQ_1cSCCmAx",
                    label: "tab1",
                    value: "tab1",
                    panel: 0,
                  },
                  {
                    id: "Ulo8aO2Ic3vVH_pi6Y8oH",
                    label: "tab2",
                    value: "tab2",
                    panel: 0,
                  },
                ],
                popupTriggerType: "BUTTON",
                secondaryPanel: "",
                mappedFieldId: "",
                mappedColumnId: "",
                nestedListMaxLevel: 3,
                nestedList: [
                  {
                    id: "zHXLo4oW4fFQHmzF17ZEL",
                    label: "Item1",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                  {
                    id: "x-DBami9h9zIXgEk8IxVY",
                    label: "Item2",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                ],
                nestedListItemsPerLine: [],
                nestedListFieldType: "SHORT_TEXT",
                nestedListTypeSettings: {},
                formula: "",
                loginType: "EZOFIS_LOGIN",
                additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                masterFormTableColumns: [],
                repositoryId: 0,
                repositoryField: "",
                repositoryFieldParent: "",
                addressMatchingFields: [
                  {
                    id: "UuYuFYR2ScGinxjOnQKtq",
                    addressColumn: "CITY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "jpZ5Oz94krIeBjDcG_VQZ",
                    addressColumn: "COUNTRY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "hNM_uSEDtvCPqSgz9AA_l",
                    addressColumn: "STATE",
                    selectFieldColumn: [],
                  },
                  {
                    id: "qkqjGzNcdPcwMEzsuITzo",
                    addressColumn: "POSTALCODE",
                    selectFieldColumn: [],
                  },
                ],
                formSyncField: [],
                defaultValueInSelectField: "NONE",
              },
              validation: {
                fieldRule: "OPTIONAL",
                contentRule: "DECIMAL",
                minimum: "",
                maximum: "",
                allowedFileTypes: [],
                maxFileSize: 10,
                dateRange: "NONE",
                timeRange: "NONE",
                maxiDays: 0,
                miniDays: 0,
                maxiTime: 0,
                miniTime: 0,
                answerIndicator: "NO",
                documentExpiryField: "",
                enableSettings: [],
                mandatorySettings: [],
                readonlySettings: [],
                timeFormat: "12",
              },
              aiSettings: {
                formControlValidate: {
                  optionsType: "",
                  masterFormId: 0,
                  masterFormColumn: [],
                  formFields: "",
                  existingFormFields: [],
                  conditionFields: [],
                },
                fileDataValidate: [],
                imageFileValidate: {
                  optionsType: "",
                  formFields: [],
                },
              },
            },
            dateFilter: 0,
          },
          {
            id: "b46P7ke4NjNyHtLzMvGQJ",
            label: "PasswordType",
            type: "SINGLE_SELECT",
            settings: {
              general: {
                hideLabel: false,
                size: "col-6",
                visibility: "NORMAL",
                placeholder: "",
                tooltip: "",
                dividerType: "SOLID",
                url: "",
              },
              specific: {
                optionsType: "CUSTOM",
                masterTable: "",
                masterTableColumn: "",
                customOptions: "OTP, Password",
                separateOptionsUsing: "COMMA",
                allowToAddNewOptions: false,
                optionsPerLine: 0,
                defaultValue: "CUSTOM",
                autoGenerateValue: {
                  prefix: "Form",
                  suffix: "DATE_TIME",
                },
                customDefaultValue: "",
                showColumnTotal: false,
                allowMultipleFiles: false,
                ratingIcon: "STAR",
                ratingIconCount: 5,
                allowHalfRating: false,
                allowMultipleSignatures: false,
                tableColumns: [],
                tableRowsType: "ON_DEMAND",
                tableFixedRowCount: 0,
                qrValue: false,
                tableFixedRowLabels: [],
                matrixColumns: [
                  {
                    id: "HAEeqi5R8u3wX4X4UU2Hi",
                    label: "column",
                  },
                  {
                    id: "YvpB6JCp9YAvtbS2wh8Z7",
                    label: "column",
                  },
                  {
                    id: "acAYn4bl6GxfDfc5FSpCD",
                    label: "column",
                  },
                ],
                matrixRows: [
                  {
                    id: "iBFW4qsJEbRWSlxlNMVit",
                    label: "row",
                  },
                  {
                    id: "3dzjmktnqBMP3ownSHUky",
                    label: "row",
                  },
                  {
                    id: "X7_4xdXL396WfzFGPzCWr",
                    label: "row",
                  },
                ],
                matrixType: "SHORT_TEXT",
                matrixTypeSettings: {},
                textContent: "",
                fibFields: [],
                tabList: [
                  {
                    id: "VBmosJOuAoppHka_nH6ur",
                    label: "tab1",
                    value: "tab1",
                    panel: 0,
                  },
                  {
                    id: "hsRYKQ2MiLA-eujt52Fte",
                    label: "tab2",
                    value: "tab2",
                    panel: 0,
                  },
                ],
                popupTriggerType: "BUTTON",
                secondaryPanel: "",
                mappedFieldId: "",
                mappedColumnId: "",
                nestedListMaxLevel: 3,
                nestedList: [
                  {
                    id: "yo77oEa08UVi6a17LmWXb",
                    label: "Item1",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                  {
                    id: "rMy2VwGdBoydIRiiBfnT6",
                    label: "Item2",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                ],
                nestedListItemsPerLine: [],
                nestedListFieldType: "SHORT_TEXT",
                nestedListTypeSettings: {},
                formula: "",
                loginType: "EZOFIS_LOGIN",
                additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                masterFormTableColumns: [],
                repositoryId: 0,
                repositoryField: "",
                repositoryFieldParent: "",
                addressMatchingFields: [
                  {
                    id: "SZVK3gwuI-dtO5dgU7OVy",
                    addressColumn: "CITY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "rUHzJCm2GFa5hRHDL8bML",
                    addressColumn: "COUNTRY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "-U3Ywh3NaD8ZmLsJjnQPf",
                    addressColumn: "STATE",
                    selectFieldColumn: [],
                  },
                  {
                    id: "Hm1VQJDrz4VvFdN9oTjIx",
                    addressColumn: "POSTALCODE",
                    selectFieldColumn: [],
                  },
                ],
                formSyncField: [],
                defaultValueInSelectField: "NONE",
                masterFormConditionColumn: [
                  {
                    id: "LQ9kh3Px6VIGiIoZxasbD",
                    formColumn: "",
                    masterColumn: "",
                  },
                ],
              },
              validation: {
                fieldRule: "OPTIONAL",
                contentRule: "",
                minimum: "",
                maximum: "",
                allowedFileTypes: [],
                maxFileSize: 10,
                dateRange: "NONE",
                timeRange: "NONE",
                maxiDays: 0,
                miniDays: 0,
                maxiTime: 0,
                miniTime: 0,
                answerIndicator: "NO",
                documentExpiryField: "",
                enableSettings: [],
                mandatorySettings: [],
                readonlySettings: [],
                timeFormat: "12",
              },
              aiSettings: {
                formControlValidate: {
                  optionsType: "",
                  masterFormId: 0,
                  masterFormColumn: [],
                  formFields: "",
                  existingFormFields: [],
                  conditionFields: [],
                },
                fileDataValidate: [],
                imageFileValidate: {
                  optionsType: "",
                  formFields: [],
                },
              },
            },
            dateFilter: 0,
          },
          {
            id: "jPrBG4saATqt9imz-YG3q",
            label: "Password",
            type: "PASSWORD",
            settings: {
              general: {
                hideLabel: false,
                size: "col-6",
                visibility: "NORMAL",
                placeholder: "",
                tooltip: "",
                dividerType: "SOLID",
                url: "",
              },
              specific: {
                optionsType: "CUSTOM",
                masterTable: "",
                masterTableColumn: "",
                customOptions: "Option1, Option2",
                separateOptionsUsing: "COMMA",
                allowToAddNewOptions: false,
                optionsPerLine: 0,
                defaultValue: "CUSTOM",
                autoGenerateValue: {
                  prefix: "Form",
                  suffix: "DATE_TIME",
                },
                customDefaultValue: "",
                showColumnTotal: false,
                allowMultipleFiles: false,
                ratingIcon: "STAR",
                ratingIconCount: 5,
                allowHalfRating: false,
                allowMultipleSignatures: false,
                tableColumns: [],
                tableRowsType: "ON_DEMAND",
                tableFixedRowCount: 0,
                qrValue: false,
                tableFixedRowLabels: [],
                matrixColumns: [
                  {
                    id: "XPCvWtIYRI8kzqs6Ck4t8",
                    label: "column",
                  },
                  {
                    id: "zUEczIbhw3-OOAuSwLrrC",
                    label: "column",
                  },
                  {
                    id: "G-8DtxDNJK-na7UgATLDR",
                    label: "column",
                  },
                ],
                matrixRows: [
                  {
                    id: "SKrQnH_J-3aOe1IsZ-hp5",
                    label: "row",
                  },
                  {
                    id: "6nBELvVawmCDgOkCcfE26",
                    label: "row",
                  },
                  {
                    id: "r2a_PvOPSQSYr2GsDvbuK",
                    label: "row",
                  },
                ],
                matrixType: "SHORT_TEXT",
                matrixTypeSettings: {},
                textContent: "",
                fibFields: [],
                tabList: [
                  {
                    id: "3R9nQ_SZdJEmVDoS4aPg9",
                    label: "tab1",
                    value: "tab1",
                    panel: 0,
                  },
                  {
                    id: "2EfV7GiOYPIvKDqD2UX3i",
                    label: "tab2",
                    value: "tab2",
                    panel: 0,
                  },
                ],
                popupTriggerType: "BUTTON",
                secondaryPanel: "",
                mappedFieldId: "",
                mappedColumnId: "",
                nestedListMaxLevel: 3,
                nestedList: [
                  {
                    id: "z-dMiS1MCIspcqs03TJxs",
                    label: "Item1",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                  {
                    id: "qgGzfjfnqI_SAFj0BhmjG",
                    label: "Item2",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                ],
                nestedListItemsPerLine: [],
                nestedListFieldType: "SHORT_TEXT",
                nestedListTypeSettings: {},
                formula: "",
                loginType: "EZOFIS_LOGIN",
                additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                masterFormTableColumns: [],
                repositoryId: 0,
                repositoryField: "",
                repositoryFieldParent: "",
                addressMatchingFields: [
                  {
                    id: "gDBdcAuae0H0vcmuL23TH",
                    addressColumn: "CITY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "C-QM74NrC4TIpmyefI-GJ",
                    addressColumn: "COUNTRY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "O2vZQw_acsHwuMyLBJAvb",
                    addressColumn: "STATE",
                    selectFieldColumn: [],
                  },
                  {
                    id: "m8RlETFmQot4JnH1arOH0",
                    addressColumn: "POSTALCODE",
                    selectFieldColumn: [],
                  },
                ],
                formSyncField: [],
                defaultValueInSelectField: "NONE",
              },
              validation: {
                fieldRule: "OPTIONAL",
                contentRule: "",
                minimum: "",
                maximum: "",
                allowedFileTypes: [],
                maxFileSize: 10,
                dateRange: "NONE",
                timeRange: "NONE",
                maxiDays: 0,
                miniDays: 0,
                maxiTime: 0,
                miniTime: 0,
                answerIndicator: "NO",
                documentExpiryField: "",
                enableSettings: [],
                mandatorySettings: [],
                readonlySettings: [],
                timeFormat: "12",
              },
              aiSettings: {
                formControlValidate: {
                  optionsType: "",
                  masterFormId: 0,
                  masterFormColumn: [],
                  formFields: "",
                  existingFormFields: [],
                  conditionFields: [],
                },
                fileDataValidate: [],
                imageFileValidate: {
                  optionsType: "",
                  formFields: [],
                },
              },
            },
            dateFilter: 0,
          },
          {
            id: "dVrbyA6lKrGS4dsdvh2Td",
            label: "ConfirmPassword",
            type: "PASSWORD",
            settings: {
              general: {
                hideLabel: false,
                size: "col-6",
                visibility: "NORMAL",
                placeholder: "",
                tooltip: "",
                dividerType: "SOLID",
                url: "",
              },
              specific: {
                optionsType: "CUSTOM",
                masterTable: "",
                masterTableColumn: "",
                customOptions: "Option1, Option2",
                separateOptionsUsing: "COMMA",
                allowToAddNewOptions: false,
                optionsPerLine: 0,
                defaultValue: "CUSTOM",
                autoGenerateValue: {
                  prefix: "Form",
                  suffix: "DATE_TIME",
                },
                customDefaultValue: "",
                showColumnTotal: false,
                allowMultipleFiles: false,
                ratingIcon: "STAR",
                ratingIconCount: 5,
                allowHalfRating: false,
                allowMultipleSignatures: false,
                tableColumns: [],
                tableRowsType: "ON_DEMAND",
                tableFixedRowCount: 0,
                qrValue: false,
                tableFixedRowLabels: [],
                matrixColumns: [
                  {
                    id: "Jv2KbJjbK08mVEhsxU0-c",
                    label: "column",
                  },
                  {
                    id: "Vd47xsisf0mreIiTrR6Qn",
                    label: "column",
                  },
                  {
                    id: "ELwVpM6ZnB1rjWCBHfh17",
                    label: "column",
                  },
                ],
                matrixRows: [
                  {
                    id: "K6rwT8hxCsIOlbkBqVQw_",
                    label: "row",
                  },
                  {
                    id: "FuBm4t_YkNHOvbRZ7nxip",
                    label: "row",
                  },
                  {
                    id: "GfFhm5YEj9-StQZYTBYZ1",
                    label: "row",
                  },
                ],
                matrixType: "SHORT_TEXT",
                matrixTypeSettings: {},
                textContent: "",
                fibFields: [],
                tabList: [
                  {
                    id: "VESX8iDAnn8RzBUZ4ONzC",
                    label: "tab1",
                    value: "tab1",
                    panel: 0,
                  },
                  {
                    id: "2EhgX-jjTmEh-iu2ZQNdU",
                    label: "tab2",
                    value: "tab2",
                    panel: 0,
                  },
                ],
                popupTriggerType: "BUTTON",
                secondaryPanel: "",
                mappedFieldId: "",
                mappedColumnId: "",
                nestedListMaxLevel: 3,
                nestedList: [
                  {
                    id: "1TsOeEhkHoEU_JvaujcX2",
                    label: "Item1",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                  {
                    id: "duCf6zGuwbuhjgB1wqErQ",
                    label: "Item2",
                    level: 1,
                    showChildren: true,
                    children: [],
                  },
                ],
                nestedListItemsPerLine: [],
                nestedListFieldType: "SHORT_TEXT",
                nestedListTypeSettings: {},
                formula: "",
                loginType: "EZOFIS_LOGIN",
                additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
                masterFormTableColumns: [],
                repositoryId: 0,
                repositoryField: "",
                repositoryFieldParent: "",
                addressMatchingFields: [
                  {
                    id: "A93opn337VPK7W707vplu",
                    addressColumn: "CITY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "52-Bm2b_V_LR_Gx89HDND",
                    addressColumn: "COUNTRY",
                    selectFieldColumn: [],
                  },
                  {
                    id: "q27coQWLGx0J7Tq_GhVHX",
                    addressColumn: "STATE",
                    selectFieldColumn: [],
                  },
                  {
                    id: "unRF26z5dSdNGhbFY79_N",
                    addressColumn: "POSTALCODE",
                    selectFieldColumn: [],
                  },
                ],
                formSyncField: [],
                defaultValueInSelectField: "NONE",
              },
              validation: {
                fieldRule: "OPTIONAL",
                contentRule: "",
                minimum: "",
                maximum: "",
                allowedFileTypes: [],
                maxFileSize: 10,
                dateRange: "NONE",
                timeRange: "NONE",
                maxiDays: 0,
                miniDays: 0,
                maxiTime: 0,
                miniTime: 0,
                answerIndicator: "NO",
                documentExpiryField: "",
                enableSettings: [],
                mandatorySettings: [],
                readonlySettings: [],
                timeFormat: "12",
              },
              aiSettings: {
                formControlValidate: {
                  optionsType: "",
                  masterFormId: 0,
                  masterFormColumn: [],
                  formFields: "",
                  existingFormFields: [],
                  conditionFields: [],
                },
                fileDataValidate: [],
                imageFileValidate: {
                  optionsType: "",
                  formFields: [],
                },
              },
            },
            dateFilter: 0,
          },
        ],
      },
    ],
    secondaryPanels: [
      {
        id: "aEeos7TGWbzrNNEGOB5ea",
        settings: {
          title: "",
          description: "",
        },
        fields: [],
      },
    ],
    settings: {
      general: {
        name: "",
        description: "",
        layout: "CLASSIC",
        type: "MASTER",
        superUser: [],
        entryUser: [],
      },
      rules: [],
      publish: {
        publishOption: "PUPLISHED",
        publishSchedule: "",
        unpublishSchedule: "",
      },
    },
    isDeleted: false,
  };
}
