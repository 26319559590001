import { render, staticRenderFns } from "./WorkflowSettings.vue?vue&type=template&id=3b961f22&scoped=true&"
import script from "./WorkflowSettings.vue?vue&type=script&lang=js&"
export * from "./WorkflowSettings.vue?vue&type=script&lang=js&"
import style0 from "./WorkflowSettings.vue?vue&type=style&index=0&id=3b961f22&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b961f22",
  null
  
)

export default component.exports